import React from "react";

class ProductCategoryRow extends React.Component {
  render() {
    const category = this.props.category;
    const idGroup = this.props.idGroup;
    var gimg;
    if (this.props.isGlobal) {
      gimg = <img src="img/btn-open-folder.png" alt="open-folder"></img>;
    } else {
      gimg = <img src="img/btn-close-folder.png" alt="close-folder"></img>;
    }

    return (
      <div className="row order-line order-group" onClick={() => { this.props.groupChange(idGroup) }}>
        <div className="col-12 strong">
          {gimg}
          {category}
        </div>
      </div>);
  }
}

class ProductRow extends React.Component {
  render() {
    const product = this.props.product;
    const price = this.props.price;
    const ost = this.props.ost;
    const ost_skl = this.props.ost_skl;
    const orde = this.props.orde;
    const idProduct = this.props.idProduct;

    return (
      React.createElement("a", { onClick: () => { this.props.handleOrde({ name: product, price: price, ost: ost, orde: orde, ost_skl: ost_skl, id: idProduct }) } },
        React.createElement("div", { className: this.props.cls },
          React.createElement("div", { className: "col-7" }, product),
          React.createElement("div", { className: "col-3 order-line-n" },
            React.createElement("span", null, ost_skl),
            React.createElement("span", null, price)),
          React.createElement("div", { className: "col-2 order-line-n" },
            React.createElement("span", { className: "strong" }, orde),
            React.createElement("span", null, ost)))));
  }
}

export class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: props.globalGroup
    };

    this.handleGroupChange = this.handleGroupChange.bind(this);
  }

  handleGroupChange(value) {
    this.setState({ group: value });
  }

  render() {
    const rowsG = [];
    const rowsGG = [];
    const rows = [];
    if (this.props.searchText == "" && !this.props.orderOnly) {
      var tr = true;
      var cls;
      this.props.products.forEach(product => {
        if (parseInt(this.state.group) != parseInt(product.idGroup) && parseInt(this.state.group) != parseInt(product.id)) { return }

        if (product.isCategory == 1 && parseInt(this.state.group) == parseInt(product.id)) {
          rowsGG.push(
            React.createElement(ProductCategoryRow, {
              category: product.name,
              key: product.id,
              idGroup: parseInt(product.id) == parseInt(this.state.group) ? product.idGroup : product.id,
              isGlobal: parseInt(product.id) == parseInt(this.state.group) ? true : false,
              groupChange: this.handleGroupChange
            }));
        } else if (product.isCategory == 1) {

          rowsG.push(
            React.createElement(ProductCategoryRow, {
              category: product.name,
              key: product.id,
              idGroup: parseInt(product.id) == parseInt(this.state.group) ? product.idGroup : product.id,
              isGlobal: parseInt(product.id) == parseInt(this.state.group) ? true : false,
              groupChange: this.handleGroupChange
            }));

        } else {

          if ((this.props.restOnly && parseFloat(product.ost_skl) > 0) || (!this.props.restOnly)) {
            if (tr) {
              cls = "row order-line tab-2r";
            } else {
              cls = "row order-line";
            }
            tr = !tr;
            rows.push(
              React.createElement(ProductRow, {
                product: product.name,
                price: product.price + " ₴",
                ost_skl: product.ost_skl,
                ost: product.ost,
                orde: product.orde,
                handleOrde: this.props.handleOrde,
                idProduct: product.id,
                key: product.id,
                cls: cls
              }));
          }
        }
      });

    } else if (this.props.searchText != "") {
      this.props.products.forEach(product => {
        const st = String(this.props.searchText).toUpperCase();
        if (product.isCategory == 0 && String(product.name).toUpperCase().indexOf(st) >= 0) {
          if (tr) {
            cls = "row order-line tab-2r";
          } else {
            cls = "row order-line";
          }
          tr = !tr;
          rows.push(
            React.createElement(ProductRow, {
              product: product.name,
              price: product.price + " ₴",
              ost_skl: product.ost_skl,
              ost: product.ost,
              orde: product.orde,
              handleOrde: this.props.handleOrde,
              idProduct: product.id,
              key: product.id,
              cls: cls
            }));
        }
      });
    } else if (this.props.orderOnly) {
      this.props.products.forEach(product => {
        if (product.isCategory == 0 && product.orde > 0) {
          if (tr) {
            cls = "row order-line tab-2r";
          } else {
            cls = "row order-line";
          }
          tr = !tr;
          rows.push(
            React.createElement(ProductRow, {
              product: product.name,
              price: product.price + " ₴",
              ost_skl: product.ost_skl,
              ost: product.ost,
              orde: product.orde,
              handleOrde: this.props.handleOrde,
              idProduct: product.id,
              key: product.id,
              cls: cls
            }));
        }
      });
    }
    rowsG.sort((a, b) => (a.props.category > b.props.category) ? 1 : -1)
    rows.sort((a, b) => (a.props.product > b.props.product) ? 1 : -1)

    return (<div className="container ordTable">
      {rowsGG}
      {rowsG}
      {rows}
    </div>);
  }
}

export default ProductTable;