import React from 'react';

export class Setings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skey: props.skey,
      url: props.url,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    localStorage.setItem(name, value);
    this.setState({
      [name]: value
    });
  }

  render() {
    let dbname = localStorage.getItem('dbname');
    return (<div className="set-titl">
      <div className="g-top-info">
        <div className="top-info">
          <label>{dbname}</label>
          <img className="img-list-klient-orde" src="img/btn-exit.png" alt='' onClick={() => { this.props.setStage(0) }} />
        </div>
      </div>
      <div className="set-row">
        <label>Настройки</label>
      </div>
      <div className="set-row">
        Строка синхронизации<input name="url" type="text" value={this.state.url} onChange={this.handleInputChange} />
      </div>
      <div className="set-row">
        Ключ<input name="skey" type="text" value={this.state.skey} onChange={this.handleInputChange} />
      </div>
      <div className="set-row">
        <button
          className="btn btn-primary btn-block m-2 w-100 "
          onClick={() => {
            localStorage.removeItem("pass");
            localStorage.removeItem("url");
            localStorage.removeItem("skey");
            localStorage.removeItem("name");
            localStorage.removeItem("dbname");
            localStorage.removeItem("globalGroup");
            window.location.reload()  
          }}
        >Очистить настройки
        </button>
        <button
          className="btn btn-primary btn-block m-2 w-100 "
          onClick={() => {
            localStorage.clear();
            window.location.reload() 
          }}>
          Очистить ВСЕ
        </button>
      </div>
    </div>

    );
  }
}

export default Setings;