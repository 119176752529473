import React, { Component } from 'react';
import Visit from './Visit';
import Journal from './Journal';
import Setings from './Setings';
import { Navigate } from 'react-router-dom';


function formatDate(date) {

  var dd = date.getDate();
  if (dd < 10) dd = '0' + dd;

  var mm = date.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  var yy = date.getFullYear() % 100;
  if (yy < 10) yy = '0' + yy;

  return dd + '.' + mm + '.' + yy + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
}

function formatDateSQL(date) {

  var dd = date.getDate();
  if (dd < 10) dd = '0' + dd;

  var mm = date.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  var yy = date.getFullYear() % 100;
  if (yy < 10) yy = '0' + yy;

  return "" + yy + '.' + mm + '.' + dd + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

}


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      sKlient: false,
      sProduct: false,
      sUpload: false,
      sDebet: false,
    };

    this.setStage = this.setStage.bind(this);
    this.goSync = this.goSync.bind(this);
  }

  goSync() {
    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";
    this.setState({
      sKlient: true,
      sProduct: true,
      sUpload: true,
      sDebet: true,
    });
    //////klient/////////////   
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url + "mt-api.php", true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          localStorage.setItem("KLIENT", xhr.responseText);
          localStorage.setItem("statusD", Date.now());
          localStorage.setItem("lastOrder", 0);

          this.setState({ sKlient: false });
        } else {
          this.setState({ sKlient: false });
          console.error(xhr.statusText);
        }
      }
    }.bind(this);

    xhr.onerror = function (e) {
      console.error(xhr.statusText);
      this.setState({ sKlient: false });
    }.bind(this);
    xhr.send("id=1&key=" + skey);
    ////////////////////////

    ///////////produkt/////////////

    var xhr1 = new XMLHttpRequest();
    xhr1.open("POST", url + "mt-api.php", true);
    xhr1.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr1.onload = function (e) {
      if (xhr1.readyState === 4) {
        if (xhr1.status === 200) {
          localStorage.setItem("PRODUCTS", xhr1.responseText);
          localStorage.setItem("statusD", Date.now());
          this.setState({ sProduct: false });
        } else {
          this.setState({ sProduct: false });
          console.error(xhr1.statusText);
        }
      }
    }.bind(this);

    xhr1.onerror = function (e) {
      this.setState({ sProduct: false });
      console.error(xhr1.statusText);
    }.bind(this);
    xhr1.send("id=2&key=" + skey);
    ///////////produkt/////////////

    ///////////debet/////////////

    var xhr2 = new XMLHttpRequest();
    xhr2.open("POST", url + "mt-api.php", true);
    xhr2.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {
          localStorage.setItem("DEBET", xhr2.responseText);
          localStorage.setItem("statusD", Date.now());
          this.setState({ sDebet: false });
        } else {
          this.setState({ sDebet: false });
          console.error(xhr2.statusText);
        }
      }
    }.bind(this);

    xhr2.onerror = function (e) {
      this.setState({ sDebet: false });
      console.error(xhr2.statusText);
    }.bind(this);
    xhr2.send("id=3&key=" + skey);
    ///////////debet/////////////


    ///////////upload////////////////
    var orders = JSON.parse(localStorage.getItem("orders")) || [];
    var sendOrde = [];
    orders.forEach(val => {
      if (!val.sync) {
        val.dtsql = formatDateSQL(new Date(parseInt(val.dt)));
        sendOrde.push(val);
      }
    });
    if (sendOrde.length > 0) {
      var xhr3 = new XMLHttpRequest();
      xhr3.open("POST", url + "mt-api-in.php", true);
      xhr3.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');
      xhr3.onload = function (e) {
        if (xhr3.readyState === 4) {
          if (xhr3.status === 200) {
            localStorage.setItem("statusD", Date.now());
            localStorage.setItem("status", 0);
            localStorage.removeItem("orders");
            this.setState({ sUpload: false });
          } else {
            this.setState({ sUpload: false });
            console.error(xhr3.statusText);
          }
        }
      }.bind(this);

      xhr3.onerror = function (e) {
        this.setState({ sUpload: false });
        console.error(xhr3.statusText);
      }.bind(this);
      var sendOrders = {};
      sendOrders.key = "sdfg45mnf;bdaj049gans;";
      sendOrders.part = "orde";
      sendOrders.body = sendOrde;
      sendOrders.skey = skey;

      xhr3.send(JSON.stringify(sendOrders));
    } else {
      this.setState({ sUpload: false });
    }
    /////////////////////////////////

  }

  setStage(val) {
    this.setState({ stage: val });
  }

  render() {
    var skey = localStorage.getItem("skey") || false;
    if (!skey) { return (<Navigate to={localStorage.getItem("lp") + "Login"} replace={true} />); }

    var d = new Date();
    var n = d.getDay();
    n = n === 0 ? 7 : n;
    var KLIENT = [];
    var PRODUCTS = [];

    switch (this.state.stage) {
      case 1:
        KLIENT = JSON.parse(localStorage.getItem("KLIENT")) || [];
        PRODUCTS = JSON.parse(localStorage.getItem("PRODUCTS")) || [];
        return (React.createElement(Visit, { klient: KLIENT, products: PRODUCTS, setStage: this.setStage, dayOfWek: n }));

      case 2:
        KLIENT = JSON.parse(localStorage.getItem("KLIENT")) || [];
        PRODUCTS = JSON.parse(localStorage.getItem("PRODUCTS")) || [];
        return (React.createElement(Journal, { klient: KLIENT, products: PRODUCTS, setStage: this.setStage, }));

      case 3:
        let skey = localStorage.getItem("skey") || "";
        let url = localStorage.getItem("url") || "";
        return (React.createElement(Setings, { setStage: this.setStage, url: url, skey: skey }));

      default:
        let name = localStorage.getItem("name") || "";
        let dbname = localStorage.getItem("dbname") || "";
        let sost = "";
        let d = localStorage.getItem("statusD") || "";
        let sostd = formatDate(new Date(parseInt(d)));
        let sostClass = "";
        if (this.state.sKlient || this.state.sProduct || this.state.sUpload || this.state.sDebet) {
          sost = "Обмен";
          sostClass = "text-warning";
        } else {
          if (localStorage.getItem("status") == 1) {
            sost = "Необходимо обновить";
            sostClass = "text-danger";
          } else {
            sost = "Ok";
            sostClass = "text-success";
          }
        }

        return (
          React.createElement("div", { className: "titl" },
            React.createElement("div", { className: "titl-info-block" },

              <table className='w-100'>
                <thead></thead>
                <tbody>
                  <tr>
                    <td className='br-1'>Имя</td>
                    <td>{name}</td>
                  </tr>
                  <tr className='bt-1 bb-1 '>
                    <td className='br-1'>База данных</td>
                    <td>{dbname}</td>
                  </tr>
                  <tr>
                    <td className="br-1">Состояние</td>
                    <td className={sostClass}>{sostd} {sost}</td>
                  </tr>
                </tbody>
              </table>

            ),
            React.createElement("div", { className: "titl-btn-blok" },

              React.createElement("div", { className: "cbtn-titl" },
                React.createElement("button", { onClick: () => { this.setStage(1) } },
                  "Визит"
                )
              ),
              React.createElement("div", { className: "cbtn-titl" },
                React.createElement("button", { onClick: () => { this.setStage(2) } },
                  "Журнал"
                )
              ),
              React.createElement("div", { className: "cbtn-titl" },
                React.createElement("button", { onClick: () => { this.goSync() } },
                  "Обновить"
                )
              ),
              React.createElement("div", { className: "cbtn-titl" },
                React.createElement("button", { onClick: () => { this.setStage(3) } },
                  "Настройки"
                )
              ),
              React.createElement("div", { className: "cbtn-titl" },
                React.createElement("button", { onClick: () => { localStorage.removeItem('skey'); window.location.reload(); } },
                  "Выход"
                )
              )
            )
          )

        );

    }
  }
}



export default Main;