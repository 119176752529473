import React from "react";

export class BlockBtn extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col btn-top">
            <img src="img/btn-note.png" alt="note" onClick={() => { this.props.noteShow() }} />
          </div>
          <div className="col btn-top">
            <img src="img/btn-money.png" alt="search" onClick={() => { this.props.debetShow() }} />
          </div>
          <div className={this.props.isSearch ? 'col btn-top bg-warning' : 'col btn-top'}>
            <img src="img/btn-filtr.png" alt="filtr" onClick={() => { this.props.filtrShow() }} />
          </div>
          <div className="col btn-top">
            <img src="img/btn-save.png" alt="save" onClick={() => { if (this.props.isRemote) { this.props.backVisit() } else { this.props.saveVisit() } }} />
          </div>

        </div>
      </div>
    );
  }
}

export default BlockBtn;