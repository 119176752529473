import React, { Component } from 'react';

function formatDate(date) {

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    var yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    return dd + '.' + mm + '.' + yy;
}

class Debet extends Component {

    render() {
        let dbname = localStorage.getItem('dbname');
        const rows = [];
        var orders = JSON.parse(localStorage.getItem("DEBET")) || [];
        var tr = false;
        var cls;
        orders.forEach(val => {
            if (val.klid != this.props.idKlient) {
                return;
            }
            if (tr) {
                cls = "row tab-2r";
            } else {
                cls = "row";
            }
            tr = !tr;
            let dolg = parseFloat(val.dolg).toFixed(2);
            let pt = parseFloat(val.pt).toFixed(2);
            let rt = parseFloat(val.rt).toFixed(2);
            rows.push(
                <div className={cls} key={`rl-${val.id}`}>
                    <div className="col-5">
                        <div>{formatDate(new Date(val.d))} </div>
                        <div>{val.dok} {val.nom}</div>
                        <div>{val.nn}</div>
                    </div>
                    <div className="col-2">{pt}</div>
                    <div className="col-2">{rt}</div>
                    <div className={dolg < 0 ? "col-2 text-danger" : "col-2 text-success"}>{dolg}</div>
                </div>
            );
        });

        return (
            <div className="titl">
                <div className="g-top-info">
                    <div className="top-info">
                        <label>{dbname}</label>
                        <img className="img-list-klient-orde" src="img/btn-exit.png" alt='' onClick={() => { this.props.setStage(0) }} />
                    </div>
                </div>
                <div className="set-row">
                    <label>Журнал взаиморасчетов</label>
                </div>
                <div className="list-debet">
                    <div className="row bg-light bb-1 bt-1" key={`rl-00`}>
                        <div className="col-5">
                            <div>Дата </div>
                            <div>Документ</div>
                        </div>
                        <div className="col-2">Приход</div>
                        <div className="col-2">Расход</div>
                        <div className="col-2" >Сальдо</div>
                    </div>
                    {rows}
                </div>

            </div>

        );
    }
}

export default Debet;