import React from "react";

export class BlockInfo extends React.Component {
    render() {
      const suma = this.props.suma;
      const dolg = this.props.dolg;
      const klient = this.props.klient;
      const withdrawal = this.props.withdrawal;
      return (
        React.createElement("div", { className: "container block-info" },
          React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12" },
              React.createElement("div", { className: "order-info border-bottom" },
                React.createElement("span", { className: "order-info-klient" }, klient)),
              React.createElement("div", { className: "order-info" },
                React.createElement("span", null, "Сальдо"),
                React.createElement("span", (parseFloat(dolg).toFixed(2) < 0) ? { className: "TextRed" } : null, parseFloat(dolg).toFixed(2))),
              React.createElement("div", { className: "order-info" },
                React.createElement("span", null, "Инкасация"),
                React.createElement("span", null, withdrawal)),
              React.createElement("div", { className: "order-info" },
                React.createElement("span", null, "Сумма заказа"),
                React.createElement("span", null, parseFloat(suma).toFixed(2))
              )))));
    }
  }
  
  export default BlockInfo;
  