import React from 'react';

class ModalOrde extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orde: this.props.selectProduct.orde,
            ost: this.props.selectProduct.ost,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (target.type === 'number') { value = Number(value) }

        this.setState({
            [name]: value
        });
        this.props.selectProduct[name] = value;
    }

    render() {
        if (this.props.isShow) {
            /*                 if (this.state.orde != this.props.selectProduct.orde) {
                                this.setState({
                                    orde: this.props.selectProduct.orde
                                });
                            }
             */
            const selProd = this.props.selectProduct;
            return (
                React.createElement("div", { className: "modal m1" },
                    React.createElement("div", { className: "modal-dialog" },
                        React.createElement("div", { className: "modal-content" },
                            React.createElement("div", { className: "modal-header border-bottom" },
                                React.createElement("p", { className: "order-info-klient" }, selProd.name)),
                            React.createElement("div", { className: "modal-body" },
                                React.createElement("div", { className: "tov-prop" },
                                    React.createElement("span", null, "Цена"),
                                    React.createElement("span", null, selProd.price)),
                                React.createElement("div", { className: "tov-prop" },
                                    React.createElement("span", null, "Остаток на складе"),
                                    React.createElement("span", null, selProd.ost_skl)),
                                React.createElement("div", { className: "tov-orde" },
                                    React.createElement("label", { htmlFor: "orde" }, "Заказ"),

                                    React.createElement('input', {
                                        type: 'number',
                                        step: "0.01",
                                        name: 'orde',
                                        id: 'orde',
                                        value: this.props.selectProduct.orde,
                                        onChange: this.handleInputChange,
                                        onInput: function(evt) {
                                            let str = String(evt.target.value);
                                            if (str.length > 1 && str.indexOf('0') == 0 && (str.indexOf(',') != 1 || str.indexOf('.') != 1))
                                                evt.target.value = parseFloat(evt.target.value);
                                        }
                                    })
                                ),
                                React.createElement("div", { className: "tov-orde" },
                                    React.createElement("label", { htmlFor: "ost" }, "Остаток"),
                                    React.createElement('input', {
                                        type: 'number',
                                        step: "0.01",
                                        name: 'ost',
                                        id: 'ost',
                                        value: this.props.selectProduct.ost,
                                        onChange: this.handleInputChange,
                                        onInput: function(evt) {
                                            let str = String(evt.target.value);
                                            if (str.length > 1 && str.indexOf('0') == 0 && (str.indexOf(',') != 1 || str.indexOf('.') != 1))
                                                evt.target.value = parseFloat(evt.target.value);
                                        }
                                    })
                                )),
                            React.createElement("div", { className: "tov-btn" },
                                React.createElement("button", { className: "bt1", onClick: () => { this.props.handleSaveOrde(selProd) } }, "Ok"),
                                React.createElement("button", { className: "bt1", onClick: () => { this.props.handleCancel() } }, "Отмена"))))
                ));

        } else {
            return (
                React.createElement("div", null, ""))
        }
    }

}
export default ModalOrde;