import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Login from './Components/Login';
import Main from './Components/Main';


function App() {
  localStorage.setItem("lp", "/");
  localStorage.setItem("lpi", "http://mt.sproba.com.ua/img/");
  return (<BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/Login" element={<Login />} />
    </Routes>
  </BrowserRouter>
  );
}

//const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();

