import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import React from "react";
import OrderBlock from "./OrderBlock";

export class Visit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sdayOfWek: props.dayOfWek,
      visitStage: 0,
      selectKlient: {
        id: 0,
        dolg: '0',
        withdrawal: 0,
        name: ''
      }

    };
    this.setdayOfWek = this.setdayOfWek.bind(this);
    this.goOrde = this.goOrde.bind(this);
    this.backVisit = this.backVisit.bind(this);
    this.saveVisit = this.saveVisit.bind(this);

  }

  setdayOfWek(val) {
    this.setState({ sdayOfWek: val.target.value });
  }

  goOrde(id) {
    var sk;
    this.props.klient.forEach(val => {
      if (val.id === id) {
        sk = val;
      }
    })
    this.setState({ selectKlient: sk, visitStage: 1 });
  }

  backVisit() {
    this.setState({ visitStage: 0 });
  }

  saveVisit() {
    var lastOrder = localStorage.getItem("lastOrder") || 0;
    var d = Date.now();
    var sum = 0;
    var orde = {
      id: (parseInt(lastOrder) + 1),
      dt: d,
      sync: false,
      klientId: this.state.selectKlient.id,
      klientName: this.state.selectKlient.name,
      withdrawal: this.state.selectKlient.withdrawal,
      komment: this.state.selectKlient.komment,
      blokCheck: this.state.selectKlient.blokCheck,
      products: []
    }
    this.props.products.forEach(val => {
      if (val.orde !== 0 || val.ost !== 0) {
        orde.products.push({ id: val.id, orde: val.orde, ost: val.ost });
        sum += val.orde * val.price;
      }
    })
    orde.sum = sum;
    var orders = JSON.parse(localStorage.getItem("orders")) || [];

    orders.push(orde);
    localStorage.setItem("status", 1);
    localStorage.setItem("orders", JSON.stringify(orders));
    localStorage.setItem("lastOrder", (parseInt(lastOrder) + 1));
    this.setState({ visitStage: 0 });
  }

  render() {
    switch (this.state.visitStage) {
      case 1:
        var selectKlient = this.state.selectKlient;
        selectKlient.blokCheck = [
          { id: "c1", name: "Самовывоз", val: false },
          { id: "c2", name: "Документы отсрочка", val: false },
          { id: "c3", name: "БпФ", val: false },
          { id: "c4", name: "ББ", val: false },
          { id: "c5", name: "ДФ", val: false },
          { id: "c6", name: "Налоговая", val: false }
        ];
        selectKlient.komment="";
        var prod = this.props.products;
        prod.forEach(val => { val.ost = 0; val.orde = 0; });
        return (React.createElement(OrderBlock, {
          klient: selectKlient,
          products: prod,
          backVisit: this.backVisit,
          saveVisit: this.saveVisit
        }));

        break;
      default:

        var rows = [];
        var tr = false;
        var cls;
        var list = this.props.klient;
        list.sort((a, b) => (a['d' + this.state.sdayOfWek] > b['d' + this.state.sdayOfWek]) ? 1 : -1)
        list.forEach(val => {
          if (val['d' + this.state.sdayOfWek] != 0 || this.state.sdayOfWek == 0) {
            if (tr) {
              cls = "row tab-2r";
            } else {
              cls = "row";
            }
            tr = !tr;
            rows.push(

              <div className={cls} key={val.id}>
                <div className="col-8">{val.name}</div>
                <div className="col-2">{parseFloat(val.dolg).toFixed(2)}</div>
                <div className="col-2">
                  <img className="img-list-klient-orde" src="img/btn-note.png" alt='' onClick={() => { this.goOrde(val.id) }} />
                </div>
              </div>
            );
          }
        })
        let dbname = localStorage.getItem('dbname');
        return (
          <div className="titl">
            <div className="g-top-info">
              <div className="top-info">
                <label>{dbname}</label>
                <img className="img-list-klient-orde" src="img/btn-exit.png" alt='' onClick={() => { this.props.setStage(0) }} />
              </div>
            </div>
            <div className="day-of-weak border-bottom">
              <label>День недели:</label>
              <select value={this.state.sdayOfWek} onChange={this.setdayOfWek}>
                <option value="1">Понедельник</option>
                <option value="2">Вторник</option>
                <option value="3">Среда</option>
                <option value="4">Четверг</option>
                <option value="5">Пятница</option>
                <option value="6">Суббота</option>
                <option value="7">Воскресенье</option>
                <option value="0">Все</option>
              </select>
            </div>
            <div className="list-klient">
              {rows}
            </div>
          </div>

        );
    }
  }
}

export default Visit;