import React from "react";
import ModalNote from "./ModalNote";
import ModalFiltr from "./ModalFiltr";
import ModalOrde from "./ModalOrde";
import BlockInfo from "./BlockInfo";
import BlockBtn from "./BlockBtn";
import ProductTable from "./ProductTable";
import Debet from "./Debet";


export class OrderBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderOnly: false,
      restOnly: false,
      searchText: "",
      selectKlient: props.klient,
      product: props.products,
      selectProduct: { name: "", price: 0, ost: 0, orde: 0, ost_skl: 0, id: 0 },
      isOrde: false,
      isNote: false,
      isSearch: false,
      isFiltr: false,
      isDebet: false
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleOrde = this.handleOrde.bind(this);
    this.handleSaveOrde = this.handleSaveOrde.bind(this);
    this.noteShow = this.noteShow.bind(this);
    this.noteClose = this.noteClose.bind(this);
    this.debetShow = this.debetShow.bind(this);
    this.debetClose = this.debetClose.bind(this);
    this.filtrShow = this.filtrShow.bind(this);
    this.filtrClose = this.filtrClose.bind(this);
  }


  handleCancel() {
    this.setState({ isOrde: false });
  }

  handleSaveOrde(selProduct) {
    var orde = this.state.product;
    orde.forEach(product => {
      if (product.id === selProduct.id) {
        product.ost = selProduct.ost;
        product.orde = selProduct.orde;
      }
    })

    var isSearch = !this.state.orderOnly && !this.state.restOnly ? false : true;

    this.setState({ isOrde: false, product: orde, searchText: "", isSearch: isSearch });
  }

  handleOrde(selProd) {
    this.setState({
      isOrde: true,
      selectProduct: selProd
    });
  }

  noteShow() {
    this.setState({ isNote: true });
  }

  noteClose(val) {

    this.setState({ isNote: false, selectKlient: val });

  }

  debetShow() {
    this.setState({ isDebet: true });
  }

  debetClose() {

    this.setState({ isDebet: false });

  }

  filtrShow() {
    this.setState({ isFiltr: true });
  }

  filtrClose(val, valSearch) {
    var pv = { isFiltr: false, searchText: valSearch };
    var isSearch = valSearch == "" ? false : true;
    val.forEach(v => {
      pv[v.id] = v.val;
      isSearch = v.val ? true : isSearch;
    });

    pv.isSearch = isSearch;
    this.setState(pv);

  }

  getSuma() {
    let suma = 0;
    this.state.product.forEach(product => {
      if (product.isCategory == 0) {
        suma += parseFloat(product.price) * parseFloat(product.orde);
      }
    });
    return suma;
  }

  render() {
    let dbname = localStorage.getItem('dbname');
    let globalGroup = localStorage.getItem('globalGroup');
    if (this.state.isDebet) {
      return (React.createElement(Debet, {
        idKlient: this.state.selectKlient.id,
        setStage: this.debetClose
      }));
     } else {
      return (
        React.createElement("div", { className: "titl-order" },
          <div className="g-top-info">
            <div className="top-info">
              <label>{dbname}</label>
              <img className="img-list-klient-orde" src="img/btn-exit.png" alt='' onClick={() => { this.props.backVisit(0) }} />
            </div>
          </div>,
          React.createElement(BlockBtn, {
            noteShow: this.noteShow,
            debetShow: this.debetShow,
            filtrShow: this.filtrShow,
            backVisit: this.props.backVisit,
            saveVisit: this.props.saveVisit,
            isSearch: this.state.isSearch,
            isRemote: this.props.isRemote,
            key: 0
          }),
          React.createElement("div", { className: "list-orde" },
            React.createElement(BlockInfo, {
              suma: this.getSuma(),
              withdrawal: this.state.selectKlient.withdrawal,
              dolg: this.state.selectKlient.dolg,
              klient: this.state.selectKlient.name,
              key: 1
            }),
            React.createElement(ProductTable, {
              products: this.state.product,
              searchText: this.state.searchText,
              orderOnly: this.state.orderOnly,
              restOnly: this.state.restOnly,
              handleOrde: this.handleOrde,
              globalGroup: globalGroup,
              key: 2
            })),
          React.createElement(ModalOrde, {
            isShow: this.state.isOrde,
            selectProduct: this.state.selectProduct,
            handleCancel: this.handleCancel,
            handleSaveOrde: this.handleSaveOrde,
            key: 3
          }),
          React.createElement(ModalNote, {
            isShow: this.state.isNote,
            selectKlient: this.state.selectKlient,
            noteClose: this.noteClose,
            key: 4
          }),
          React.createElement(ModalFiltr, {
            isShow: this.state.isFiltr,
            filtr: [
              { id: "restOnly", name: "Только в наличии", val: this.state.restOnly },
              { id: "orderOnly", name: "Показать заказ", val: this.state.orderOnly }
            ],
            filtrClose: this.filtrClose,
            key: 6
          })
        )
      );
    }
  }
}

export default OrderBlock;